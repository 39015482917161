import React, { useEffect } from 'react'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Footer from '../../components/ui/footer/Footer'

export default function Errorpage() {
  useEffect(() => {     document.title = 'SL Health - Error';   }, [])
  return (
    <>
      <main>
        <Navbar2/>
            <div style={{ display: 'flex', width: '100%', height: '50vh', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(180deg, rgba(245,130,31,1) 0%, rgba(245,130,31,0.5551470588235294) 35%, rgba(255,255,255,0.43469887955182074) 100%)' }}>
                <h1 style={{ color: 'var(--color-text-light)'}}>Page not found: 301</h1>
            </div>
        <Footer/>
      </main>
    </>
  )
}