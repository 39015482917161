import React from 'react'
import './Compbox.css'

export default function Compbox() {
  return (
    <div className='wrapper compbox_tietoja'>
      <div className='page-bodytext'>
          <h3>Yrittäjänä työskennellessä olen huomannut, että vierivä kivi ei sammaloidu ja kehittämällä niin
          itseä kuin ammattitaitoa, mieli pysyy virkeänä ja voi auttaa ihmisiä paremmin.</h3>
          <p>Olen Sanna Laakkonen Joensuusta. Työajan ulkopuolella minut pitävät virkeänä perheeni ja 
            koira, joka huolehtii päivittäisestä ulkoilusta joka säällä. Olen valmistunut sairaanhoitajaksi 
            vuonna 1997. Lasten ollessa pieniä kouluttauduin dipl. vyöhyketerapeutiksi Charles Ersdalin 
            oppien mukaan. Vyöhyketerapian myötä opin ymmärtämään ihmistä kokonaisuutena ja ihmisen fysiologia 
            alkoi kiinnostamaan minua yhä enemmän.</p>
          <p>Oman toiminimen SL Health perustin vuonna 2008. Tein kuitenkin sairaanhoitajan
          työtä lasten ollessa pieniä. Toimin Joensuussa sairaanhoitajana pääasiassa päivystystyössä,
          ensin perusterveydenhuollon päivystyksessä ja sittemmin yhteispäivystyksessä - antoisaa ja
          vaativaa työtä, sekä upeita ihmisiä!</p>
          <p>Kiinnostus ihmisen hyvinvoinnin hoitamiseen kokonaisvaltaisesti sekä omien lasteni suolisto ja
          iho-oireiden korjaantuminen ravinnon avulla, ”ajoivat” minut Paula Heinosen luennoille ja
          käymään kaikki Pro Healthin järjestämät ravintovalmentajille suunnatut luennot. Olin koukussa!
          Vuonna 2014-2020 toimin FLT Ravintovalmentajana ja senkin jälkeen olen käyttänyt näitä
          oppeja osana toimintaani.</p>
          <img src={process.env.PUBLIC_URL + '/assets/images/IMG_20220706_115744.jpg'} alt='picture of Sanna Laakkonen'/>
          <p>-Sanna Laakkonen</p>
      </div>
      <div className='page-sidebar'>
      <h3>SL Health</h3>
          <p>Yritys on perustettu vuonna 2008, yhden naisen intohimosta auttaa muita ja elää omannäköistä
          elämää. Tärkeimpiä asioita ei näe silmillä, vaan sydämellä.</p>
      </div> 
    </div>      
  )
}
