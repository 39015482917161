import React, { useEffect } from 'react'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Footer from '../../components/ui/footer/Footer'
import MapTo from '../../components/ui/map/MapTo'
import { Ravintovalmennus } from '../../components/ui/palvelu.content'

export default function Palvelut() {
  useEffect(() => {     document.title = 'SL Health - Ravintovalmennus';   }, [])
  return (
    <>
        <Navbar2/>
        <Ravintovalmennus/>
        <MapTo/>
        <Footer/>
    </>
  )
}
