import React from 'react'
import "./ServiceContent.css"

export default function Hieronta() {
  return (
    <>
        <header id='Header_palvelut'>
            <img src={ process.env.PUBLIC_URL + '/assets/images/physio-1778029_1280.jpg'} alt='selkähieronta' className='headerpicture palvelut' />
            <div className='palvelutheadershadow'>
                <h1>Klassinen hieronta</h1>
            </div>
        </header>
        <div className='wrapper'>
            <div className='page-bodytext'>
                <h3>Kaipaatko rentoutusta tai apua kireisiin lihaksiin?</h3>
                <p>Hieronta sopii kaikenikäisille ja onkin
                perinteinen pehmytkudoksille tarkoitettu hoitomuoto, joka lievittää kipua, rentouttaa kehoa ja
                mieltä sekä palauttaa lihaksistoa rasituksesta. Lisäksi hieronta mm. edistää aineenvaihduntaa,
                lievittää stressiä, tehostaa laskimoverenkiertoa ja aktivoi elimistön omia korjaavia mekanismeja.</p>
                <p>Olen käynyt Itä-Suomen Liikuntaopiston ISLON hieronnan ammattitutkinnon vuonna 2023.
                Suurella mielenkiinnolla ja innolla olen ottanut hierontapalvelut osaksi työnkuvaani.</p>
                <h3>Vauvahieronta:</h3>
                <p>Vauvahieronta on kivutonta ja se tehdään koko keholle lapsen ehdoilla. Yleensä yhdistän
                vyöhyketerapian vauvahieronnan yhteyteen. Kosketuksella saadaan yhteys ja luodaan
                tasapainoa vauvalle. Lukuisat tutkimukset osoittavat kosketuksen tärkeydestä vauvalle ja hänen
                terveydelleen. Vauvahieronta onkin lempeää kosketusta.</p>
            </div>    
        </div>
    </>
  )
}
