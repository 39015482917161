import React from 'react'
import './Quotation.css'

export default function Quotation() {
  return (
    <div id='Q_cont'>
        <section className='Quotation'>
            <h1>Päivän ajatus</h1>
            <p>"Sinun kanssasi viettämäni päivä on suosikkipäiväni. Joten tänään on uusi suosikkipäiväni"
            </p>
            <span>- Nalle Puh -</span>
        </section>
    </div>
  )
}
