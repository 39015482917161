import React from 'react'
import './TietojaHeader.css'

export default function TietojaHeader() {
  return (
    <header id='Header_tietoja'>
      <img src={process.env.PUBLIC_URL +'/assets/images/nettisivut ajanvaraus 849821_1280.jpg'} alt='nainen kannettavalla tietokoneella' className='headerpicture tietoja' />
      <div className='tietojaheadershadow'>
        <h1>SL Health ja Sanna</h1>
      </div>
    </header>
    )
}
