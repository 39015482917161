import React, { useState } from 'react'
import './Contactform.css'
import './OtayhteyttaHeader.css'

export default function Contactform() {
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    text: ''
  });

  const validateForm = () => {
    let newErrors = {};
  
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Etunimi on pakollinen';
    }
  
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Sukunimi on pakollinen';
    }
  
    if (!formData.email.trim() || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Anna validi sähköpostiosoite';
    }
  
    if (!formData.text.trim()) {
      newErrors.text = 'Viesti ei voi olla tyhjä';
    }
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);

    const baseURL = process.env.NODE_ENV === 'production' ? 'https://www.slhealth.fi/api/lahetasahkoposti' :'http://localhost:5000/api/lahetasahkoposti';

    if (validateForm()) {
      try {
        const response = await fetch(baseURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert('Kiitos viestistäsi. Sähköposti lähetettiin onnistuneesti.');
          // Optionally, reset the form
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            text: ''
          });
        } else {
          alert('Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Valitettavasti lomaketta ei voitu lähettää. Yritä uudelleen myöhemmin tai ota yhteys henkilökuntaan.');
      } finally {
        setIsSending(false);
      }
    } else {
      alert('Tarkista kentät:\n' + Object.values(errors).join('\n'));
      setIsSending(false);
    }
  };
  return (
    <>
    <img src={process.env.PUBLIC_URL + '/assets/images/1d7c9607-5a95-48f0-9e89-3a2b4f6d663d.jpg'} alt='kädet syvdämen asennossa aurinkoa kohti' className='headerpicture_contact' />
    <div className='otayhteyttaheadershadow'/>
    <div className='otayhteyttaheadertextbox'>
      <h1>Ollaan yhteydessä!</h1>
    </div>
    <div className="form-box">
        <span className='formdisabled'>Tulossa pian:<br/>Lomake toistaiseksi poissa käytöstä</span>
        <form className="contact-form">
            <label> Yhteydenotto-<br/>lomake </label>
            <input id="firstName" name="firstName" placeholder="Etunimi" value={formData.firstName} onChange={handleChange}/>
            <input id="lastName" name="lastName" placeholder="Sukunimi" value={formData.lastName} onChange={handleChange}/>
            <input id="email" name="email" placeholder="Sähköposti" value={formData.email} onChange={handleChange}/>
            <textarea id="text" name="text" placeholder="Viesti..." value={formData.text} onChange={handleChange}/>
            <button type="submit" id="submitBtn" className="submitBtn" disabled={isSending}>{isSending ? 'Odota...' : 'Lähetä'}</button>
        </form>
        <aside>
                <h4>SL Health</h4>
                <section>
                    <h5>Puhelin:</h5>
                    <address>+358 41 434 6075</address>
                </section>
                <section>
                    <h5>Sähköposti:</h5>
                    <address>sanna@slhealth.fi</address>
                </section>
                <section>
                    <h5>Osoite:</h5>
                    <address>Ahomansikantie 21,<br/>
                    80160, <br/>
                    Joensuu,<br/>
                    Suomi, FI</address>
                </section>
                <section>
                    <h5>Y-tunnus: </h5>
                    <address>2220355-4</address>
                </section>
        </aside>
    </div>
    </>
  )
}
