import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';
import { navItems } from "../../assets/NavItems";


export default function Footer() {
  return (
    <footer>
      <div className='container'>
        <Link to='/'><img src={process.env.PUBLIC_URL + "/assets/images/SL_HEALTH_LOGO_TXT_SANNA_SLHEALTH_WHITE.png"} alt="Sl Health logo" className="footerlogo"/></Link>
        <div className='ftr_contact'>
              <span id='footer-information'>
                  Yhteystiedot:<br/>
                  
                  Sanna Laakkonen<br/>
                  sanna@slhealth.fi<br/>
                  +358 414346075<br/>
                  Ahomansikantie 21, <br/>
                  80160 Joensuu<br/>
                  <br/>
                  Y-tunnus: 2220355-4
              </span>
        </div>
        <div className='footer_nav'>
                <span id='footer-information'>Navigoi:</span>
                <ul>
                  {navItems.map(item => {
                      return(
                          <li key={item.id} className='footerNav' id={item.itemId}>
                              <Link to={item.path}>{item.title}</Link>
                          </li>
                      );}
                  )}
                  <li className='footerNav'>
                    <Link to={'/tietosuojaseloste'}>Tietosuojaseloste</Link>
                  </li>
                </ul>
        </div>
        <div className='footer_some'>
              <span id='footer-information'> Seuraa somessa:</span>
              <div>
                <a href='https://www.instagram.com/sannalaakkonen_slhealth/'><img src={process.env.PUBLIC_URL + "/assets/images/instagram-svg.png"} alt="instagram logo" className="svgicon ig"/> Instagram</a>
              </div>
        </div>
      </div>
      <span id='tekjanoikeus'>@jonilaakkonen2023</span>
    </footer>
  )
}