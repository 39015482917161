import React, { useEffect } from 'react'
import Services2 from '../../components/ui/palvelut2.services/Services2'
import ServicesHeader from '../../components/ui/palvelut.header/ServicesHeader'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Footer from '../../components/ui/footer/Footer'
import MapTo from '../../components/ui/map/MapTo'

export default function Palvelut() {
  useEffect(() => {     document.title = 'SL Health - Palvelut';   }, [])
  return (
    <>
        <Navbar2/>
        <ServicesHeader/>
        <Services2/>
        <MapTo/>
        <Footer/>
    </>
  )
}
