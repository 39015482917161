import React from 'react'
import "./ServiceContent.css"

export default function Vyohyketerapia() {
  return (
    <>
        <header id='Header_palvelut'>
            <img src={ process.env.PUBLIC_URL + '/assets/images/kantapaa.jpg'} alt='vauvanvarpaat' className='headerpicture palvelut' />
            <div className='palvelutheadershadow'>
                <h1>Vyöhyketerapia</h1>
            </div>
        </header>
        <div className='wrapper'>
            <div className='page-bodytext'>
                <h3>Vyöhyketerapiaa vastasyntyneestä varttuneempaan väestöön, uniongelmista ummetukseen ja monenlaista siltä väliltä. Elimistösi pystyy lähes ihmeisiin ammattitaidolla tehdyn vyöhyketerapian avulla.</h3>
                <p>Vyöhyketerapiakäynti kestää aikuisella noin tunnin ajan. Kaikki heijastealueet jalkaterien
                alueelta käsitellään sormin ja rystysten avulla jokaisella käyntikerralla. Hoitokertojen määrä on
                yksilöllinen ja ongelmasta riippuvainen. Kroonisiin, pidempään jatkuneisiin ongelmiin tarvitaan
                usein muutama (esimerkiksi viisi) käsittelykertaa, kun akuutti lihaskipu voi helpottaa jo yhdellä
                käyntikerralla kokonaan. On muistettava, että ”terveyttä on helpompi hoitaa kuin sairautta”
                (Charles Ersdal).</p>
                <p>Lasten ja vauvojen hoitokerta on noin 30-45 minuuttia. Ensimmäiselle käynnille on vauvoille ja
                pienille lapsille hyvä varata 45min aika. Vyöhyketerapia tehdään aina lapsen ja vauvan
                tahtisesti.</p>
            </div>
            <div className='page-sidebar'>
            <h3>Vyöhyketerapialla voidaan <br/> vaikuttaa mm.</h3>
                <ul>
                    <li>Vauvojen itkuisuus ja koliikkikivut</li>
                    <li>Lasten erilaiset käyttäytymispulmat</li>
                    <li>Urheilevien lasten ja nuorten kiputilat</li>
                    <li>Kasvukivut</li>
                    <li>Stressi, päänsärky, migreeni</li>  	
                    <li>Kohonnut verenpaine</li>  	
                    <li>Yleinen väsymys, uupumus, alhainen vireystila</li>  	
                    <li>Erilaiset nivel- ja lihassäryt</li>  	
                    <li>Allergia, astma, poskiontelovaivat</li>  	
                    <li>Naisten ja miesten hormonaaliset ongelmat (esim. kuukautiskivut, vaihdevuosioireet, prostata)</li>  	
                    <li>Unettomuus</li>  	
                    <li>Lapsettomuus</li>  	
                    <li>Tulehduskierteet</li>
                </ul>
            </div>        
        </div>
    </>
  )
}
