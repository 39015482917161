import React from 'react'
import './Header_etusivu.css'

export default function Header_etusivu() {
  return (
    <>
        <header id='Header_etusivu'>
            <img src={process.env.PUBLIC_URL + '/assets/images/soil-8166778_1280.jpg'} alt='vanhus ja lapsi pellolla auringon laskussa' className='headerpicture etusivu' />
            <div className='headershadow'/>
            <div className='headeroverlay'>
              <h1>Pidä huolta kehostasi. <br/> Se on ainoa kotisi, josta et voi muuttaa pois.</h1>
              <p>- Jim Rohn -</p>
            </div>
        </header>
    </>
  )
}
