export const navItems = [
  {
    id: 1,
    title: "Etusivu",
    path: "/",
    cName: "nav-item",
    position: "left",
    itemId: "unactive"
  },
  {
    id: 2,
    title: "SL Health",
    path: "/tietoja",
    cName: "nav-item",
    position: "left",
    itemId: "unactive"
  },{
    id: 3,
    title: "Hinnasto",
    path: "/palvelut",
    cName: "nav-item",
    position: "right",
    itemId: "unactive"
  },
  {
    id: 4,
    title: "Ota Yhteyttä",
    path: "/otayhteytta",
    cName: "nav-item",
    position: "left",
    itemId: "unactive"
  },
  {
    id: 5,
    title: "Ajanvaraus",
    path: "/ajanvaraus",
    cName: "nav-item",
    position: "right",
    itemId: "unactive"
  },
];
export const tietojaDownItems = [
  {
    id: 1,
    title: "Ravintovalmennus",
    path: "/ravintovalmennus",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Vyöhyketerapia",
    path: "/vyohyketerapia",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Klassinen hieronta",
    path: "/hieronta",
    cName: "submenu-item",
  },
  {
  id: 3,
  title: "Tunne- ja tietoisuustaito-ohjaus",
  path: "/tunne-ja-tietoisuustaito-ohjaus",
  cName: "submenu-item",
  },
  {
  id: 4,
  title: "Sairaanhoitajan vastaanotto",
  path: "/sairaanhoitajan-vastaanotto",
  cName: "submenu-item",
  },
  {
    id: 5,
    title: "Tietosuojaseloste",
    path: "/tietosuojaseloste",
    cName: "submenu-item",
  },
];