import React from 'react'
import './TietojaHeader.css'

export default function TietosuojaselosteHeader() {
  return (
    <header id='Header_tietoja'>
      <img src={process.env.PUBLIC_URL +'/assets/images/nettisivut ajanvaraus 849821_1280.jpg'} alt='kädet syvdämen asennossa aurinkoa kohti' className='headerpicture tietoja' />
      <div className='tietojaheadershadow'>
        <h1>Tietosuojaseloste</h1>
      </div>
    </header>
    )
}