import React, { useEffect, useState } from 'react';
import './Navbar2.css';
import { Link, useLocation } from 'react-router-dom';
import { navItems } from '../../assets/NavItems';
import MenuButton from '../menubutton/MenuButton';
import DropDownTietoja from './dropDownMenus/DropdownTietoja';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Navbar2() {
  const [dropdownT, setDropdownT] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [linkEnabled, setLinkEnabled] = useState(false);
  const location = useLocation(); // Get the current location using React Router

  // Determine the active item based on the current route
  const activeItem = navItems.find((item) => item.path === location.pathname);

  const handleLinkClick = () => {
    if (!linkEnabled) {
      // Enable the link on the first click
      setLinkEnabled(true);
    }
    // Handle other logic here...
  };

  useEffect(() => {
    setNavOpen(false); // Close the navigation menu when the route changes
  }, [location]);

  // Set body overflow based on navOpen state
  useEffect(() => {
    document.body.style.overflow = navOpen ? 'auto' : 'auto';
    return () => {
      // Revert body overflow when the component is unmounted
      document.body.style.overflow = 'auto';
    };
  }, [navOpen]);

  return (
    <nav>
      <div className="navoverlay2" />
      <div className="navbar-links">
        <div className={navOpen ? 'navShadow active' : 'navShadow'}/>
        <ul className={navOpen ? 'nav-items active' : 'nav-items'}>
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
              alt="Sl Health logo"
              className="logo-image"
            />
          </Link>
          <div className="nav-options">
            {navItems.map((item) => {
              if (item.title === 'SL Health') {
                if (navOpen === true) {
                  return (
                  <li
                    key={item.id}
                    className={`${item.cName} ${item === activeItem ? 'activeItem' : ''}`}
                    id={item.itemId}
                    onClick={() => {
                      setDropdownT(!dropdownT);
                      if (navOpen === true) {
                        setLinkEnabled(!linkEnabled);
                      }
                    }}
                    onMouseEnter={() => setDropdownT(true)}
                    onMouseLeave={() => setDropdownT(false)}
                  >
                    <Link id="navA" className={item.title} to={item.path} style={linkEnabled ? {pointerEvents: 'auto'} : { pointerEvents: 'none' }} >
                      {item.title}
                      <KeyboardArrowDownIcon
                        sx={{ fontSize: 30 }}
                        onClick={() => setDropdownT(!dropdownT)}
                      />
                    </Link>
                    {dropdownT && <DropDownTietoja/>}
                  </li>
                  );
                } else {
                  return (
                    <li
                      key={item.id}
                      className={`${item.cName} ${item === activeItem ? 'activeItem' : ''}`}
                      id={item.itemId}
                      onClick={() => setDropdownT(!dropdownT)}
                      onMouseEnter={() => setDropdownT(true)}
                      onMouseLeave={() => setDropdownT(false)}
                    >
                      <Link id="navA" className={item.title} to={item.path} >
                        {item.title}
                        <KeyboardArrowDownIcon
                          sx={{ fontSize: 30 }}
                          onClick={() => setDropdownT(!dropdownT)}
                        />
                      </Link>
                      {dropdownT && <DropDownTietoja/>}
                    </li>
                  );
                }
              }
              return (
                <li
                  key={item.id}
                  className={`${item.cName} ${item === activeItem ? 'activeItem' : ''}`}
                  >
                  <Link id="navA" className={item.title} to={item.path}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </div>
        </ul>
        
        <Link to="/">
        <img
              src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
              alt="Sl Health logo"
              className="minmenu-logo"
            />
        </Link>
        <Link to="/">
          <img src={process.env.PUBLIC_URL + '/assets/images/logo_white.png'} alt="Sl Health logo" className="minimenu-logoWhite"/>
        </Link>
        <div className="SMenuBtn" style={{ zIndex: 1001 }} onClick={() => setNavOpen(!navOpen)}>
          <MenuButton />
        </div>
      </div>
    </nav>
  );
}
