import React from 'react'
import "./ServiceContent.css"
import { Link } from 'react-router-dom'

export default function Sairaanhoitaja() {
  return (
    <>
        <header id='Header_palvelut'>
            <img src={ process.env.PUBLIC_URL + '/assets/images/sh-vastaanotto.jpg'} alt='selkähieronta' className='headerpicture palvelut' />
            <div className='palvelutheadershadow'>
                <h1>Sairaanhoitajan vastaanotto</h1>
            </div>
        </header>
        <div className='wrapper'>
            <div className='page-bodytext'>
                <h3>Sairaanhoitajan vastaanotto ammattitaidolla.</h3>
                <p>Sairaanhoitajan vastaanotto voidaan tehdä joko etävastaanottona tai lähivastaanottona. 
                Vastaanotolla tehdään kokonaisvaltaista hoidontarpeen arviointia, opastusta ja ohjausta. 
                Erityisesti ravitsemukseen ja hyvinvointiin liittyvät kysymykset ja haasteet ovat lähellä 
                sydäntä ja osaamisaluettani iästä riippumatta.</p>
                <p>Lisäksi onnistuu pienet toimenpiteet, kuten haavojen hoito (omat hoitotarvikkeet mukaan) 
                ja verenpaineen mittaus ja ohjaus.</p>
                <p><Link to="/otayhteytta">Ota rohkeasti yhteyttä.</Link></p>
            </div>    
        </div>
    </>
  )
}
