import React from 'react'
import './Palveluihin.css'
import { Link } from 'react-router-dom'

export default function Palveluihin() {
  return (
    <div className='palveluihin_cont'>
      <Link to={"/palvelut"} id='linktoPalvelut'>Tutustu seuraavaksi palveluihimme, <br/> klikkaa tästä kuplasta!</Link>
    </div>
  )
}
