import React, { useEffect } from 'react';
import './ajanvaraus.css'

function Ajanvaraus() {
      useEffect(() => {
        // Create a function to load the Vello script
        function loadVelloScript() {
          if (document.getElementById('vello-wizard-sdk')) return;
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'vello-wizard-sdk';
          script.async = true;
          script.setAttribute('data-c', 'sl-health');
          script.src = 'https://static.vello.fi/js/wizard/vwiz.js';
          document.getElementsByTagName('head')[0].appendChild(script);
        }
    
        // Load the Vello script when the component mounts
        loadVelloScript();
    
        // Cleanup when the component unmounts
        return () => {
          const velloScript = document.getElementById('vello-wizard-sdk');
          if (velloScript) {
            velloScript.remove();
          }
        };
      }, []);
    
      return (
        <>
          <div className='Ajanvaraus_text'>
            <h3>Alla olevasta ajavarauskalenterista voit varata ajan palveluihin.</h3>
            <p>Mahdolliset siirrot tai peruutukset on tehtävä 24h ennen varattua aikaa. Peruuttamattomasta ajasta veloitetaan käyntimaksu kokonaisuudessaan</p>
            <span>Mikäli et löydä sopivaa aikaa, tai sinulla on kysyttävää. Ota yhteyttä yhteydenottolomakkeen kautta tai puhelimitse.</span>
          </div>
          <div id="vello-wizard"></div>
        </>
      );
    }
    
    export default Ajanvaraus;