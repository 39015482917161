import React from "react"
import MenuIcon from '@mui/icons-material/Menu';

const MenuButton = () => {

return(
  <> 
        <li className="nav-item" id="MenuButton2">
          <span>Valikko</span>
          <MenuIcon sx={{ mr: 2 }} fontSize="large"/>         
        </li>
  </>
)}


export default MenuButton;