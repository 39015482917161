import React, { useState } from "react";
import { tietojaDownItems } from "../../../assets/NavItems"
import { Link } from "react-router-dom";

import "./dropdown.css"

function DropDownTietoja(navOpen) {

    const [dropdown, setDropdown] = useState(false);

    return (
        <ul className={dropdown ? "service-submenu clicked" : "service-submenu" } id="SV" onClick={() => setDropdown(!dropdown)}>
            {navOpen == true ? (
                <li key='99'>
                    <Link 
                    className='submenu-item' 
                    to='/tietoja' 
                    onClick={() => setDropdown(false)}
                    >
                    SL Health
                    </Link>
            </li>
            ) : null}
            {tietojaDownItems.map(item => (
                <li key={item.id}>
                    <Link 
                    className={item.cName} 
                    to={item.path} 
                    onClick={() => setDropdown(false)}
                    >
                    {item.title}
                    </Link>
                </li>
            ))}
        </ul>
)}

export default DropDownTietoja;