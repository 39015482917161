import React from 'react'
import './ajanvaraus.css'

export default function Header_ajanvaraus() {
  return (
    <header id='Header_ajanvaraus'>
        <img src={process.env.PUBLIC_URL + '/assets/images/nettisivut ajanvaraus calendar-771597_1280.jpg'} alt='Kuva kalenterista ja kynästä' className='headerpicture ajavaraus' />
        <div className='palvelutheadershadow'>
            <h1>Ajanvaraus</h1>
        </div>
    </header>
  )
}
