import React from 'react'
import './Serviceshowcase.css'
import { ServiceItems } from "../../assets/ServiceItems";
import { Link } from 'react-router-dom';

export default function Serviceshowcase() {
  return (
    <section id='services_etusivu'>
        {ServiceItems.map(item => {
                  return(
                     <div className='servicecard'> 
                        <Link to={item.path}> 
                          <figure>
                              <img src={process.env.PUBLIC_URL + `/assets/images/${item.image}`} alt='...' className='servicecard_img'/>
                              <figcaption style={{backgroundColor:`${item.backgroundColor}`}}>{item.title}</figcaption>
                          </figure>
                        </Link>
                        <Link id='linkToR' to={'/ajanvaraus'}>Varaa aika</Link>
                      </div>
                      
                )
            })}
    </section>
  )
}
