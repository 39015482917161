import React from 'react'
import './Compbox.css'

export default function Compbox() {
  return (
    <div className='wrapper compbox_tietoja'>
      <div className='page-bodytext'>
      <p><strong>Tietosuojaseloste </strong></p>
      <p>Terveys ja hyvinvointivalmennus SL Health asiakasrekisteri</p>
      <p><strong>Rekisterinpitäjä / Rekisterin yhteyshenkilö</strong></p>
      <p>Nimi: Sanna Laakkonen <br/>Osoite: Ahomansikantie 21, Joensuu<br/>Puhelinnumero: 0414346075 sanna@slhealth.fi</p>
      <p><strong>Rekisterin nimi</strong></p>
      <p>SL Health:n asiakasrekisteri</p>
      <p><strong>Rekisterin peruste, henkilötietojen ja rekisterin käyttötarkoitus</strong></p>
      <p>Asiakasrekisteriä tarvitaan asiakkaan yhteystietoja, laskutusta sekä kirjanpitoa varten. Tietoja voidaan käyttää markkinointiin rekisteröidyn suostumuksella. Henkilötietojen käsittelyn periaatteet Henkilötietojen käsittely perustuu asiakkuuteen sekä rekisteröidyn nimenomaiseen suostumukseen. Henkilötietojen käsittelyssä noudatetaan aina seuraavia periaatteita: henkilötiedot ovat asianmukaisia, olennaisia ja rajoitettuja siihen, mikä on tarpeellista suhteessa niihin tarkoituksiin, joita varten niitä käsitellään. Henkilötietoja käsitellään tavalla, jolla varmistetaan henkilötietojen asianmukainen turvallisuus, mukaan lukien suojaaminen luvattomalta ja lainvastaiselta käsittelyltä sekä vahingossa tapahtuvalta häviämiseltä, tuhoutumiselta tai vahingoittumiselta käyttäen asianmukaisia teknisiä toimia.</p>
      <p>Rekisteröidyllä on oikeus peruttaa henkilötietojen käsittelyyn antamansa suostumus. Pyyntö osoitetaan rekisterinpitäjän yhteyshenkilölle yllä olevilla yhteystiedoilla ja rekisteröidyn allekirjoituksella kirjallisesti tai sähköisesti.</p>
      <p><strong>Rekisterin tietosisältö</strong></p>
      <p>Rekisterin tietoja ovat etu- ja sukunimi, syntymäaika, osoite, puhelinnumero, ammatti sekä asiakastiedot. Säilytettävä materiaali on luottamuksellista.</p>
      <p><strong>Tietosuojavastaava</strong></p>
      <p>Tietosuojavastaavaa ei ole nimetty, koska tietoja käyttää vain Sanna Laakkonen eikä toiminnan luonne sitä edellytä.</p>
      <p><strong>Tietojen luovuttaminen muille osapuolille</strong></p>
      <p>Lähtökohtaisesti tietoja ei luovuteta muille osapuolille. Mikäli tietoja luovutetaan, perustuu luovuttaminen rekisteröidyn suostumukseen (pyydetty erillinen suostumus) ja rekisteröidyn asioiden hoitamiseen.</p><p><strong>Tietojen säilytys</strong></p>
      <p>Kerätty tieto säilytetään vain sen ajan, kun se on toiminnan kannalta merkityksellistä. Säilytysajan umpeuduttua tiedot hävitetään asianmukaisesti.  Tietoja voidaan kuitenkin tarvittaessa säilyttää kirjanpito- tai muusta pakottavasta lainsäädännöstä johtuen kyseisen lain säännösten mukaisesti myös asiakassuhteen tai muun henkilötietojen käsittelyperusteen päättymisen jälkeen.</p><p><strong>Säännönmukaiset tietolähteet</strong></p>
      <p>Asiakkaalta itseltään saadut tiedot.</p><p><strong>Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</strong></p>
      <p>Asiakasrekisterin tietoja ei säilytetä tai luovuteta EU/ETA alueen ulkopuolelle.</p><p><strong>Rekisterin suojauksen periaatteet</strong></p>
      <p>Rekisterin sähköinen materiaali säilytetään tietokoneella,  jonka tietoihin pääsee salasanoilla vain Sanna Laakkonen. Rekisterin paperilla oleva materiaali säilytetään lukollisessa kaapissa.</p><p><strong>Tarkastusoikeus</strong></p>
      <p>Rekisteriin kirjatulla henkilöllä on oikeus tarkistaa itseään koskevat dokumentit siltä osin, että muiden rekisteröityjen oikeuksia ei loukata.</p><p><strong>Oikeus vaatia tiedon korjaamista</strong></p>
      <p>Rekisteriin kirjatulla henkilöllä on oikeus vaatia kirjauksen korjaamista. Mikäli kirjauksessa on molempien osapuolten toteama virhe, se tulee korjata välittömästi. Mikäli korjausta vaaditaan tulkintaan tai muutoin epäselvään kohtaan, korjausvaade liitetään korjattavan kohdan yhteyteen, mutta ei korjata.</p>
      <p><strong>Tietoturvaloukkaukset</strong></p>
      <p>Mahdollisten tietoturvaloukkausten osalta yritys on velvollinen ilmoittamaan asiasta valvontaviranomaiselle 72 tunnin kuluessa siitä, kun rekisterinpitäjä on tullut tietoiseksi tietoturvaloukkauksesta. Tietoturvaloukkauksesta ilmoitetaan myös loukatuille henkilöille.</p>
      <p>22.5.2018</p>    
      </div>
    </div>      
  )
}
