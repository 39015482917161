import React from 'react'
import "./ServiceContent.css"

export default function Ravintovalmennus() {
  return (
    <>
        <header id='Header_palvelut'>
            <img src={ process.env.PUBLIC_URL + '/assets/images/peppermint-2496361_1280.jpg'} alt='vihreä tee' className='headerpicture palvelut' />
            <div className='palvelutheadershadow'>
                <h1>Ravintovalmennus</h1>
            </div>
        </header>
        <div className='wrapper'>
            <div className='page-bodytext'>
                <h3>Haluatko kokonaisvaltaista hyvinvointia ja terveyttä? Syömällä oikein voit vaikuttaa
hyvinvointiisi. Päivittäin tekemilläsi valinnoilla voit vaikuttaa oman elämän kulkuun monin eri
tavoin. Aloita muutos jo tänään, jotta huomenna voit paremmin!</h3>
                <p>Autan, ohjaan, opastan – Juuri sinua! Kokonaisvaltainen, yksilöllinen näkemys hyvinvointiin,
jossa huomioidaan niin edeltävät tekijät historiassa, kuin oireita laukaisevat tekijät ja nykytila.
Sairaanhoitajan ammattitaidon ja funktionaaliseen lääketieteeseen pohjautuvan
ravintovalmennuksen avulla kiinnitetään huomiota ravinnon lisäksi uneen, elämäntapoihin,
suoliston ja maksan kuntoon, hormonitoimintaan, immuunijärjestelmään sekä kehon
rakenteisiin.</p>
                <p>Olen käynyt Paula Heinosen Pro Health Oy:n järjestämän koulutuksen ravintovalmentajille.
Ravintovalmennus pohjautuu funktionaalisen lääketieteen periaatteille, joka painottaa ihmisen
biokemiallista yksilöllisyyttä sekä ravinnon ja elinympäristön vaikutusta terveyteen ja
hyvinvointiin. Lisäksi olen käynyt avoimessa yliopistossa ravitsemustieteen perusopinnot.</p>
                <p>Ota  rohkeasti yhteyttä, niin kartoitetaan sinun tilanteesi. Ravintovalmennuksen avulla voit oppia hallitsemaan kehoa ja  mieltäsi.</p>
                <h3>Lapset ja vauvat</h3>
                <p>Lasten ja vauvojen ravinto-ohjauksesta ja terveyshaasteista minulla on yli kymmenen vuoden
kokemus. Teen tarvittaessa yhteistyötä lastenlääkärin kanssa. Ota rohkeasti yhteyttä, mikäli
lapsellasi on haasteita ruokailuun tai terveyteen liittyvissä asioissa, kuten allergiat,
ruuansulatuksen haasteet, ihottumat, refluksi, infektiokierteet jne.</p>
                <p>Ravintovalmennus suunnitellaan yksilöllisesti, juuri sinun tarpeita vastaavaksi.</p>
                <p>Ensimmäinen käynti: Kesto n. 1,5- 2 tuntia. Lähetän sinulle sähköpostiin esitietokaavakkeet ja
oirekyselyn ennen vastaanottoa. Esitietokaavakkeet voit tuoda tullessasi vastaanotolle tai
lähettää ne etukäteen. Vastaanotolla käydään näiden pohjalta sinun tilanteesi läpi, kootaan
historiasi aikajanaan ja suunnitellaan hoitolinja.</p>
                <p>Seurantakäynti on tarpeellinen, koska eteneminen tapahtuu askel kerrallaan. Ajanvaraus
seurantakäyntiä varten on hyvä tehdä 1-3kk päähän ensimmäisestä käynnistä.</p>
                <p>Seurantakäynnit: Kesto n. 1 tunti. Seurantakäynnillä tarkastetaan oirekyselyn perusteella mitä
muutoksia on kehossasi tapahtunut ja mitä oireita on vielä jäljellä. Oirekuva selkiytyy
seurantakäynneillä, ja siten pystytään aina paremmin ja paremmin etenemään kohti hyvinvoivaa
kehoa.</p>
                <p>Ravintovalmennukset onnistuu sekä etä- että lähikontakteilla.</p>
                <h3>Hyödyllisiä linkkejä</h3>
                    <p>
                        <a target="_blank" href="http://www.prohealth.fi">www.prohealth.fi</a>
                        <a target="_blank" href="http://fms.fi"><br/>www.fms.fi<br/></a> 
                        <a target="_blank" href="http://www.functionalmedicine.org">www.functionalmedicine.org</a>
                    </p>
            </div>
            <div className='page-sidebar'>
            <h3>Ravintovalmennuksen avulla, voit saada apua:</h3>
                <ul>
                    <li>Kilpirauhasongelmiin&nbsp;</li>  	
                    <li>Suoliston ongelmiin ja epätasapainotiloihin&nbsp;</li>  	
                    <li>Iho-ongelmiin&nbsp;</li>  	
                    <li>Mielenterveyteen&nbsp;</li>  	
                    <li>Aivojen hyvinvointiin&nbsp;</li>  	
                    <li>Väsymykseen ja vireystilaan&nbsp;</li>  	
                    <li>Vastustuskykyyn&nbsp;</li>  	
                    <li>Allergioihin ja astmaan&nbsp;</li>  	
                    <li>Sydän- ja verisuonisairauksiin&nbsp;</li>  	
                    <li>Hormonaalisiin ongelmiin&nbsp;</li>  	
                    <li>Painon hallintaan</li>  
                </ul>
            </div>        
        </div>
    </>
  )
}
