import './App.css';
import { Routes, Route } from "react-router-dom";
import { Etusivu, Tietoja, Otayhteytta, Palvelut, PalveluRavintov, PalveluVyohyke, PalveluTTO, PalveluHieronta, PalveluSairaanhoito, Tietosuojaseloste, AjanvarausPage, Errorpage } from "../src/pages/index";
import ScrollToTop from './hooks/ScrollToTop';

function App() {
  return (
    <>
    <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Etusivu title="SL Health - Etusivu"/> } />
          <Route path="/tietoja" element={<Tietoja />} />
          <Route path='/otayhteytta' element={<Otayhteytta/>}/>
          <Route path='/palvelut' element={<Palvelut/>}/>
          <Route path='/ravintovalmennus' element={<PalveluRavintov/>}/>
          <Route path='/hieronta' element={<PalveluHieronta/>}/>
          <Route path='/tunne-ja-tietoisuustaito-ohjaus' element={<PalveluTTO/>}/>
          <Route path='/vyohyketerapia' element={<PalveluVyohyke/>}/>
          <Route path='/sairaanhoitajan-vastaanotto' element={<PalveluSairaanhoito/>}/>
          <Route path='/tietosuojaseloste' element={<Tietosuojaseloste/>}/>
          <Route path='/ajanvaraus' element={<AjanvarausPage/>}/>
          <Route path='/*' element={<Errorpage/>}/>
        </Routes>
    </>
  );
}

export default App;
