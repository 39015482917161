import React, { useEffect } from 'react'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Footer from '../../components/ui/footer/Footer'
import Palveluihin from '../../components/ui/button.palveluihin/Palveluihin'
import Contactform from '../../components/ui/contactform/Contactform'
import MapTo from '../../components/ui/map/MapTo'

export default function Otayhteytta() {
  useEffect(() => {     document.title = 'SL Health - Ota yhteyttä';   }, [])
  return (
    <>
        <Navbar2/>
        <Contactform/>
        <Palveluihin/>
        <MapTo/>
        <Footer/>
    </>
  )
}
