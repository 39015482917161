import React from 'react'
import './ServicesHeader.css'

export default function ServicesHeader() {
  return (
    <header id='Header_palvelut'>
        <img src={process.env.PUBLIC_URL + '/assets/images/leaf-3070153_1280.jpg'} alt='kädet syvdämen asennossa aurinkoa kohti' className='headerpicture palvelut' />
        <div className='palvelutheadershadow'>
            <h1>Palvelut ja Hinnasto</h1>
        </div>
    </header>
  )
}
