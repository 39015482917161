export const ServiceItems = [
  {
    id: 2,
    title: "Vyöhyketerapia",
    itemID: "unactive",
    path: "/vyohyketerapia",
    cName: "service-item",
    image: "jalan_sivu.jpg",
    prices:"60 min - 45€, 45 min - 37.50€, 30 min - 25€, Vauvan ja lapsen ensikäynti - 30€",
    buttonText: 'Lue lisää',
    backgroundColor: "var(--color-st-ss)"
  },
  {
    id: 3,
    title: "Ravintovalmennus",
    itemID: "unactive",
    path: "/ravintovalmennus",
    cName: "service-item",
    image: "heart-1394204_1280.jpg",
    prices:"Ensikäynti sis. ravintolisäohjelma - 180€, Seuranta sis. ravintolisäohjelma - 90€, Lasten ravitsemusohjaus - 50€",
    buttonText: 'Lue lisää',
    backgroundColor: "var(--color-nd-ss)"
  },
  {
    id: 4,
    title: "Klassinen hieronta",
    itemID: "unactive",
    path: "/hieronta",
    cName: "service-item",
    image: "physio-1778029_1280.jpg",
    prices:"Klassinen hieronta 90 min - 65€, Klassinen hieronta 60 min - 45€, Klassinen hieronta 45 min - 37.50€, Klassinen hieronta 30 min - 25€, Vauvat sis. vyöhyketerapia 60 min - 45€",
    buttonText: 'Lue lisää',
    backgroundColor: "var(--color-rd-ss)"
  },
  {
    id: 5,
    title: "Sairaanhoitajan vastaanotto ",
    itemID: "unactive",
    path: "/sairaanhoitajan-vastaanotto",
    cName: "service-item",
    image: "sh-vastaanotto.jpg",
    prices:"60 min - 50€, 30 min - 25€",
    buttonText: 'Lue lisää',
    backgroundColor: "var(--color-th-ss)"
  },
  {
    id: 6,
    title: "Tunne-ja tietoisuustaito-ohjaus ",
    itemID: "unactive",
    path: "/tunne-ja-tietoisuustaito-ohjaus",
    cName: "service-item",
    image: "heart-3147976_1280.jpg",
    prices:"60 min - 50€",
    buttonText: 'Lue lisää',
    backgroundColor: "var(--color-st-ss)"
  },
];