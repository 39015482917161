import React from 'react'
import './Tagline_etusivu.css'
import { Link } from 'react-router-dom'

export default function Tagline_etusivu() {
  return (
    <section className='tagline'>
      <p>Paranna hyvinvointimatkaasi: <br/> Meillä on nyt sähköinen ajanvaraus verkossa. Varaa aikasi <Link to={"/ajanvaraus"}>tästä linkistä</Link> vaikka heti!</p>
    </section>
  )
}
