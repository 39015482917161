import React from 'react'
import './Services2.css'
import { ServiceItems } from '../../assets/ServiceItems'
import { Link } from 'react-router-dom';

export default function Services() {

  return (
        <section id='Services_content2'>
            {ServiceItems.map((item, index) => {
                const isEven = index % 2 === 0;
                const containerClassName = isEven ? 'service_container even' : 'service_container odd';            
                const pricesArray = item.prices.split(',');
                return(
                    <div className={containerClassName}>
                        <img src={process.env.PUBLIC_URL + `/assets/images/${item.image}`} alt='...' className={`servicecont_img ${item.isTextVisible ? 'visible' : 'hidden'}`} />
                        <div className='service_info'>
                            <h2>{item.title}</h2>
                            <div className='prices'>
                                <div className='prices-scroll'>
                                {pricesArray.map((price, index) => (
                                    <p key={index}>{price}</p>
                                ))}
                                </div>
                            </div>
                            <section className='scardButtons'>
                                <Link className='linktoService' to={item.path}>Lue lisää</Link>
                                <Link to={'/ajanvaraus'} className='linktoReservation'>Varaa Aika</Link>
                            </section>
                        </div>
                    </div>
                )
            })}
        </section>
  )
}
