import React from 'react'
import './Infobox.css'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { Link } from 'react-router-dom';

export default function Infobox() {
  return (
    <section id='infobox'>
            <div className='info_item'>
                <BubbleChartIcon sx={{ fontSize: 50 }} className='info_icon'/>
                <h3>Palvelut</h3>
                <p>Me tarvitsemme toisia ihmisiä kulkemaan rinnallamme, joskus pienen hetken, joskus pidemmän ajan.</p>
                <Link to='/palvelut'>Lisätietoja...</Link>
            </div>
            <div className='info_item'>
                <AutoAwesomeIcon sx={{ fontSize: 50 }} className='info_icon'/>
                <h3>Yritys</h3>
                <p>Intohimona ihmisyys. Tärkeimpiä asioita ei näe silmillä, vaan sydämellä.</p>
                <Link to='/tietoja'>Lisätietoja...</Link>
            </div>
            <div className='info_item'>
                <EventAvailableIcon sx={{ fontSize: 50 }} className='info_icon'/>
                <h3>Ajanvaraus</h3>
                <p>"Istuhan kiireemmäks aikkoo ja varroo aika". Ajan voi unohtaa vain käyttämällä sitä.</p>
                <Link to='/ajanvaraus'>Varaa aika</Link>
            </div>
    </section>
  )
}
