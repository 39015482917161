import React from 'react'
import "./ServiceContent.css"

export default function TTO() {
  return (
    <>
        <header id='Header_palvelut'>
            <img src={ process.env.PUBLIC_URL + '/assets/images/tunne-ja-tietoisuustaito.jpg'} alt='Kivikasa' className='headerpicture palvelut' />
            <div className='palvelutheadershadow'>
                <h1>Tunne- ja tietoisuustaito-ohjaus</h1>
            </div>
        </header>
        <div className='wrapper'>
            <div className='page-bodytext'>
                <h3>Mietitkö koskaan ”miksi minulle aina käy näin” tai ”en voi luottaa keneenkään, sillä aina joudun
                pettymään”? Pystytkö tuntemaan esimerkiksi vihaa vai oletko vihainen koko ajan? Tiedätkö miltä
                tunteet ylipäätään tuntuu kehossa, tunnetko?</h3>
                <p>Minut sai tunne- ja tietoisuusohjauksen pariin eräänlainen uupumus ja tahto siihen, että asiat
                muuttuvat ympärilläni. Olin suorittaja ja ”multitaskauksen” maailmanmestari. En osannut olla
                hetkessä yhtään, en edes oivaltanut että en ollut hetkessä, sillä kuljin vain asiasta toiseen.
                Kävin kahdeksan kuukautta omaa matkaani Tietoisuusakatemia Johannan valmennuksessa ja
                sen jälkeen aloitin tunne- ja tietoisuustaito-ohjaaja-koulutuksen.</p>
                <p>Opin kuuntelemaan omia kehon
                tuntemuksiani ja tunnistamaan mikä tunne on omaa ja mikä toisten. Sekä opin esimerkiksi miltä
                viha tuntuu ja sallin sen olemassaolon. Koen, että oma keho on instrumentti, jota on hyvä
                huoltaa ja sen viestejä kuunnella. Kaikki tunteet on sallittuja kehossa, kun tunnistaa millaiselta
                tunne tuntuu. Tunteen kesto on lyhyt, vain 90 sekuntia, kun sitä ei vastusta.</p>
                <p>Valmennus tehdään etänä puhelimitse. Yksi puhelu kestää noin tunnin. Ääni laukaisee meidän
                peilisolujärjestelmän, joten ohjaus onnistuu, kun olet turvallisesti kaikessa rauhassa omassa
                kodissasi. Minä tunnen sinun puolestasi tunteita ja ohjaan sinua myös itse niitä tunnistamaan.</p>
                <p>Me tarvitsemme toisia ihmisiä kulkemaan rinnallamme, joskus pienen hetken, joskus pidemmän
                ajan.</p>
            </div>
        </div>
    </>
  )
}
