import React, { useEffect } from 'react'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Header_ajanvaraus from '../../components/ui/Ajanvaraus.Vello/Header_ajanvaraus'
import Ajanvaraus from '../../components/ui/Ajanvaraus.Vello/Ajanvaraus'
import Footer from '../../components/ui/footer/Footer'
import MapTo from '../../components/ui/map/MapTo'

export default function AjanvarausPage() {
  useEffect(() => {     document.title = 'SL Health - Ajanvaraus';   }, [])
  return (
    <>
      <main>
        <Navbar2/>
        <Header_ajanvaraus/>
        <Ajanvaraus/>
        <MapTo/>
        <Footer/>
      </main>
    </>
  )
}
