import React, { useEffect } from 'react'
import Navbar2 from '../../components/ui/navbar/Navbar2'
import Header_etusivu from '../../components/ui/etusivu.header/Header_etusivu'
import Tagline_etusivu from '../../components/ui/etusivu.tagline/Tagline_etusivu'
import Serviceshowcase from '../../components/ui/etusivu.serviceshowcase/Serviceshowcase'
import Quotation from '../../components/ui/etusivu.quote/Quotation'
import Infobox from '../../components/ui/etusivu.additionalinfo/Infobox'
import Footer from '../../components/ui/footer/Footer'
import MapTo from '../../components/ui/map/MapTo'

export default function Etusivu() {
  useEffect(() => {     document.title = 'SL Health - Etusivu';   }, [])
  return (
    <>
      <main>
        <Navbar2/>
        <Header_etusivu/>
        <Tagline_etusivu/>
        <Serviceshowcase/>
        <Quotation/>
        <Infobox/>
        <MapTo/>
        <Footer/>
      </main>
    </>
  )
}
